/* src/style.css */

@font-face {
  font-family: 'AeonikRegular';
  src: url('./assets/fonts/AeonikTRIAL-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AeonikBold';
  src: url('./assets/fonts/AeonikTRIAL-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-padding-top: 0rem;
  scroll-margin-top: -4rem;
}

body {
  background-color: #1B191D;
  color: #fff;
  font-family: 'AeonikRegular';
  overflow-x: hidden;
  min-width: 320px;
  scroll-padding-top: 20px;
}

button,
a {
  color: #fff;
  cursor: pointer;
}

.items-start {
  align-items: start !important;
}

input,
.textarea {
  color: #fff;
  font-size: 20px;
  text-size-adjust: none;
}

.textarea {
  padding-bottom: 150px !important;
  width: 100%;
}

.navbar {
  max-width: 1190px;
  margin: auto;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.phonelearn {
  margin-top: 0px;
  display: block;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 32;
  padding-bottom: 30px;
}

.stickynav {
  z-index: -1;
}

.stickynav button {
  /* display: none; */
  /* background-color: #000; */
}

.navbar img {
  max-width: 178px;
}

.solid-btn,
.outline-btn {
  padding: 12px 30px;
  background-color: #DF994F;
  border: 1px solid #DF994F;
  border-radius: 27px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.outline-btn {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

.navbtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
  padding-top: 25px;
}

.menuslide {
  position: absolute;
  top: 110%;
  right: 50px;
  background: white;
  color: #000;
  list-style: none;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menuslide li {
  display: flex;
  align-items: center;
  gap: 10px
}

.menuslide img {
  max-width: 25px !important;
}

.ham {
  max-width: 30px !important;
}
.swiper-button-prev:after, .swiper-button-next:after{
  color: #fff;
}
.menuslide button {
  color: #000;
  background-color: transparent;
  border: none;
  margin: 0 !important;
  font-family: 'AeonikBold';
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  text-decoration: none;
}

.hero-sec {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.hero-content {
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: 70px;
}

.hero-content h1 {
  font-family: 'AeonikBold';
  font-weight: bold;
  font-size: 93px;
  line-height: 110px;
}

.hero-content p {
  font-weight: normal;
  font-size: 28px;
  margin-top: 30px;
  line-height: 40px;
}

.herobg,
.secbg {
  position: relative;
  width: 100%;
  z-index: 0;
  object-fit: cover;
  margin-top: 14%;
}

.secbg {
  margin-top: 1%;
  z-index: -1;
}

.two-col-sec .sec-box {
  max-width: 1090px;
  margin: auto;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  top: 50%;
}

.values-section {
  padding: 160px 20px 100px 20px;
}

.values-section .swiper {
  padding: 80px 0;
  margin-top: 80px;
}

.values-section {
  position: relative;
}

.values-section .swiper-slide {
  opacity: 30%;
}

.values-section .swiper-slide-active {
  transform: scale(1.35);
  opacity: 100%;
}

.value-box {
  background-color: #131115;
  border-radius: 30px;
  padding: 57px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 277px;
  width: 100%;
  margin: auto;

}

.value-box img {
  max-width: 72px;
}

.value-box h3 {
  font-size: 24px;
  font-weight: 700;
  color: #DF994F;
  margin-top: 63px;
  margin-bottom: 20px;
  max-width: 290px;
  text-align: center;
}

.value-box p {
  font-size: 18px;
  max-width: 150px;
  text-align: center;
  line-height: 24px;
}


.values-section .sec-box {
  max-width: 900px;
  margin: auto;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.two-col-sec {
  margin-top: 80px;
  position: relative;
}

.two-col-sec .col {
  max-width: 540px;
  z-index: 10;
}

.two-col-sec .col h2,
.sec-title {
  font-size: 90px;
  line-height: 98px;
  font-family: 'AeonikBold';
  font-weight: bold;
}

.sec-title {
  text-align: center;
}

.two-col-sec .col p {
  font-size: 20px;
  max-width: 400px;
  margin-bottom: 50px;
  margin-top: 22px;
}

.section,
.section-phone {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px;
}



.img-mask {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  padding-left: 100px;
}

.hidden {
  display: none;
}

.box,
.box-phone {
  min-width: 370px;
  background-color: #131115;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  margin-right: 37px;
  padding: 48px 32px 65px 48px;
  border-radius: 32px;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  transition: .3s;
}

.box-hidden-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 97%;
  transition: .3s all;
  background: #DF994F;
  padding: 48px 48px 40px 48px;
}

.box-hidden-content h4 {
  color: #000;
  font-size: 33px;
}

.box:hover .box-hidden-content,
.box-phone:hover .box-hidden-content {
  top: 0%;
}

.box img,
.box-phone img {
  max-width: 230px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 32px;
  object-fit: contain;
  height: 100px;
}

.box h3,
.box-phone h3 {
  font-family: 'AeonikBold';
  font-weight: bold;
  font-size: 40px;
  min-height: 185px;
}

.coverflow-section {
  position: relative;
  padding: 100px 16px 90px 16px;
}

.coverflow {
  margin: auto;
  text-align: center;
  perspective: 900px;
  transform-style: preserve-3d;
}

.cover {
  height: auto;
  max-width: 340px;
  width: 100%;
  display: inline-block;
  padding: 25px 50px 46px 28px;
  margin: 0 -120px;
  transform: rotateY(45deg);
  transition: .3s;
  background-color: #000;
  text-align: left;
  cursor: pointer;
  border-radius: 20px;
  opacity: 1;
}


.cover:nth-child(1) {
  background: rgb(133, 132, 134);
  background: linear-gradient(134deg, rgba(133, 132, 134, 1) -45%, rgba(0, 0, 0, 1) 50%);
  /* border: 3px solid #A0726C; */
}

.cover:nth-child(2) {
  background: rgb(133, 132, 134);
  background: linear-gradient(134deg, rgba(133, 132, 134, 1) -45%, rgba(0, 0, 0, 1) 50%);
  /* border: 3px solid #4F4A50; */
}

.cover:nth-child(3) {
  /* border: 3px solid  #D6AA72 ; */
}

.cover:nth-child(4) {
  background: rgb(133, 132, 134);
  background: linear-gradient(134deg, rgba(133, 132, 134, 1) -65%, rgba(0, 0, 0, 1) 59%);
  /* border: 3px solid #4A4059; */
}

.cover:nth-child(5) {
  background: rgb(133, 132, 134);
  background: linear-gradient(134deg, rgba(133, 132, 134, 1) -65%, rgba(0, 0, 0, 1) 59%);
  /* border: 3px solid #7D4525; */
}

.cover h3 {
  font-family: 'AeonikBold';
  font-weight: bold;
  font-size: 38px;
  min-height: 130px;
}

.cover p {
  font-weight: 400;
  margin-top: 15px;
  font-size: 20px;
}

.coverflow .active {
  margin: 0;
  transform: none;
}

.coverflow .active~.cover {
  transform:
    rotateY(-45deg);
}

.plus-icon {
  display: inline-block;
  width: 55px;
  height: 55x;
  position: absolute;
  cursor: pointer;
  bottom: 70px;
  right: 32px;
  z-index: 212;
}

.plus-icon span {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.plus-icon span:nth-child(1) {
  transform: translateY(-50%) rotate(0deg);
}

.plus-icon span:nth-child(2) {
  transform: translateY(-50%) rotate(90deg);
}

.box:hover span:nth-child(2),
.box-phone:hover span:nth-child(2) {
  transform: translateY(-50%) rotate(0deg);
  background-color: #000;
}

.box-icon:hover span:nth-child(1),
.box-phone:hover span:nth-child(1) {
  transform: translateY(-50%) rotate(0deg);
  background-color: #000;
}

.sticky-boxes-section {
  margin-top: 90px;
}

.sticky-boxes-section .sec-box {
  max-width: 1300px;
  padding: 0 20px;
  margin: auto;
}

.sticky-box {
  padding: 27px 53px 60px 74px;
  overflow: hidden;
  /* background-color: #000; */
  background: rgb(0, 0, 0);
  /* background: linear-gradient(148deg, rgba(0, 0, 0, 1) 4%, rgba(83, 63, 91, 1) 100%); */
  overflow: hidden;
  border-radius: 26px;
}
.sticky-box {
  transform: scale(1);
  filter: brightness(100%) blur(0px);
}

.sticky-glow {
  z-index: -1;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(83, 63, 91, 1), rgba(83, 63, 91, 1) 71%);
  filter: blur(121px);
  border-radius: 1000000px;
  width: 700px;
  height: 700px;
  position: absolute;
  will-change: transform;
  transition: transform 0.1s ease;
  right: -15%;
  top: 72%;
}


.sticky-box:nth-child(2) .sticky-glow {

  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(142, 93, 69, 1), rgba(19, 17, 21, 1) 71%);
  right: 10%;
  top: 40%;
}

.sticky-box:nth-child(3) .sticky-glow {

  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(83, 63, 91, 1), rgba(83, 63, 91, 1) 71%);
  left: 5%;
  top: 27%;
}

.sticky-box:nth-child(4) .sticky-glow {
  /* background: linear-gradient(148deg, rgba(0, 0, 0, 1) 32%, #a47746 110%); */
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 1), #a47746 71%);
}



.sticky-box-bottom-content {
  display: flex;
  align-items: center;
  gap: 130px;
}

.sticky-boxes-section .sec-box {
  margin-top: 85px;
}

.sticky-box {
  margin-bottom: 35px;
  position: sticky;
  top: 5%;
  z-index: 10;

  transition: transform 0.5s ease;
  /* Smooth scaling transition */
}



.sticky-box-bottom-content img {
  max-width: 544px;
  width: 100%;
  object-fit: cover;
}

.sticky-box-bottom-content h3 {
  font-size: 67px;
  font-family: 'AeonikBold';
  font-weight: bold;
}

.sticky-box-bottom-content p {
  margin-top: 49px;
  font-weight: 300;
  font-size: 34px;
  max-width: 420px;
}

.sticky-box-top-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 38px;
  align-items: center;
  gap: 20px;
}

.sticky-box-top-content h6 {
  flex-shrink: 0;
  /* Prevent shrinking */
  margin: 0;
  /* Remove any default margin */
  white-space: nowrap;
  /* Prevent text wrapping */
}

.sticky-box-top-content img {
  width: 20px;
  /* Fixed width */
  height: 20px;
  /* Fixed height for consistency */
  margin-right: 10px;
  /* Optional spacing between image and span */
  flex-shrink: 0;
  /* Prevent shrinking */
}

.sticky-box-top-content .linespan {
  flex-grow: 1;
  /* Take up remaining space */
  height: 1px;
  /* Ensure it's a line */
  background-color: white;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.marquee {
  display: flex;
  gap: 150px;
  align-items: center;
  padding-left: 0%;
  animation: scroll 40s linear infinite;
  font-size: 1.5rem;
  color: #333;
}

.marquee img {
  height: 60px;
  width: auto;
  object-fit: contain;
  aspect-ratio: 2/1;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.partners-section {
  padding: 80px 0 80px 0;
}

.partners-section .sec-title {
  margin-bottom: 140px;
}

.get-in-touch-section {
  padding: 90px 20px 90px 20px;
}

.get-in-touch-section form {
  max-width: 1160px;
  width: 100%;
  margin: 86px auto 0 auto;
}

.get-in-touch-section form .form-col-2 {
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
}

.get-in-touch-section form .form-col-2 input,
.get-in-touch-section form .form-col-1 .textarea {
  width: 50%;
  background: transparent;
  border-radius: 9px;
  border: 1px solid white;
  padding: 29px 33px 25px 33px;
}

.get-in-touch-section p {
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
}

.get-in-touch-section form .form-col-2 input::placeholder,
.get-in-touch-section form .form-col-1 .textarea::placeholder {
  color: #6A6A6A;
  font-size: 20px;
  font-family: 'AeonikRegular';
}

.get-in-touch-section form .form-col-1 .textarea {
  width: 100%;
  background: transparent;
  border-radius: 9px;
  border: 1px solid white;
  padding: 29px 33px 25px 33px;
}

.get-in-touch-section form .form-col-1 {
  margin-bottom: 33px;
}

.form-col-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-col-between a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.footer {
  background-color: #131115;
  padding: 38px 20px 80px 20px;
}

.footer .sec-box {
  max-width: 1200px;
  margin: auto;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: start;
}

.footerlogo img {
  max-width: 43px;
  width: 100%;
  object-fit: contain;
}

.footermenu ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 25px;
}

.footermenu ul li button {
  color: #8A8A8A;
  background-color: transparent;
  border: none;
  font-size: 13px;
  text-decoration: none;
}

.footericons ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 25px;
}

.footericons ul li img {
  max-width: 20px;
  width: 100%;
  object-fit: contain;
}

.footericons ul li p {
  color: #8A8A8A;
  font-size: 13px;
  margin-left: 14px;
}


.canvas-wrapper {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  /* background-color: #000; */
}

#fluid {
  width: 100%;
  height: 100%;
  position: absolute;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  color: #000;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 80%;
  max-width: 400px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media(max-width:1240px) {
  .cover {
    margin: 0 -80px;
    max-width: fit-content;
  }

  .cover h3 {
    font-size: 34px;
  }

  .cover p {
    font-size: 18px;
  }

  .hero-content h1 {
    font-size: 64px;
    line-height: 70px;
  }

  .hero-content p {
    font-size: 22px;
    margin-top: 14px;
    line-height: 30px;
  }

  .two-col-sec .col h2,
  .sec-title {
    font-size: 60px;
    line-height: 67px;
  }

  .two-col-sec .col p {
    font-size: 18px;
  }

  .box h3,
  .box-phone h3 {
    font-size: 34px;
    min-height: 155px;
  }

  .box img,
  .box-phone img {
    max-width: 170px;
  }

  .box,
  .box-phone {
    min-width: 320px;
  }

  .plus-icon {
    width: 36px;
  }

  .sticky-box-bottom-content h3 {
    font-size: 50px;
  }

  .sticky-box-bottom-content p {
    margin-top: 30px;
    font-size: 26px;
    max-width: 363px;
  }

}

@media(min-width:601px) {
  .desktophidden {
    display: none;
  }
}

.showphone {
  display: none !important;
}

@media(max-width:600px) {
  html {
    scroll-padding-top: 4rem;
  }

  .phonehidden {
    display: none !important;
  }

  .showphone {
    display: block !important;
  }

  .menuslide .showphone {
    display: flex !important;
  }

  .herobg {
    margin-top: 0%;
    width: 100%;
  }


  .hero-content {

    padding-top: 40px;
  }

  .hero-content h1 {
    font-size: 76px;
    line-height: 80px;
    max-width: 250px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .two-col-sec .col h2 {
    font-size: 44px;
    line-height: 40px;
  }

  .sec-title {
    font-size: 38px;
    line-height: 60px;
    max-width: 400px;
    text-align: center;
    margin: auto;
  }

  .section-phone .swiper {
    height: 480px;
  }

  .sticky-boxes-section .sec-title {
    max-width: 300px;
  }

  .section-phone .swiper {
    margin-top: 60px;
  }

  .sticky-box * {
    text-align: left !important;
  }

  .sticky-box-bottom-content {
    align-items: start;
  }

  .box-phone {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }

  .cover {
    padding: 30px 20px 60px 20px;
    border-radius: 16px;
  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    background: #D9D9D9 !important;
  }

  .coverflowphone .swiper {
    min-height: 350px;
  }

  .cover h3 {
    font-size: 40px;
    min-height: 60px;
  }

  .cover p {
    font-size: 20px;
  }

  .row {
    flex-direction: column;
  }

  .solid-btn {
    padding: 16px;
    font-size: 20px;
    min-width: 227px;
    color: #000;
    border-radius: 32px;
  }

  .navbar img {
    max-width: 120px;
  }

  .outline-btn {
    border: none;
  }

  .hero-content p {
    font-size: 22px;
    margin-top: 14px;
    line-height: 30px;
    max-width: 255px;

  }

  .hero-content p br {
    display: none;
  }

  .two-col-sec .row {
    justify-content: center;
    align-items: center !important;
  }

  .two-col-sec .col h2 {
    font-size: 54px;
    line-height: 58px;
    text-align: center;
    max-width: 320px;
  }

  .two-col-sec {
    margin-top: 0;
  }

  .two-col-sec .col p {
    font-size: 20px;
    text-align: center;
    margin-bottom: 26px;
    max-width: 243px;
    line-height: 30px;
  }

  .two-col-sec .col h2 br {
    display: none;
  }

  .two-col-sec .col {
    text-align: center;
  }

  .secbg {
    margin-top: 59%;
    width: 100%;
    object-position: center;
  }

  .coverflowphone .cover {
    margin: 0 !important;
    transform: none;
    width: 100%;
    min-width: 100%;
    background: linear-gradient(134deg, rgba(133, 132, 134, 1) -45%, rgba(0, 0, 0, 1) 76%);
  }

  .coverflow-section {

    padding: 30px 16px 30px 16px;
  }

  .coverflowphone .coverflow {
    perspective: inherit;
  }

  .box,
  .box-phone {
    padding: 30px 20px 65px 20px;
    min-width: 280px;
    min-height: 460px;
  }
  .value-box {

    padding: 85px 32px;

    max-width: 320px;
  }
  .box h3,
  .box-phone h3 {
    font-size: 36px;
    line-height: 42px;
  }

  .box img,
  .box-phone img {
    max-width: 214px;
    width: 100%;
    margin: 45px auto 45px auto;
  }

  .partners-section .sec-title {
    margin-bottom: 60px;
  }

  .marquee img {
    height: 48px;
  }

  .partners-section {
    padding: 40px 0 40px 0;
  }

  .get-in-touch-section {
    padding: 40px 20px 40px 20px;
  }

  .footer-row {
    flex-direction: column;
  }

  .footer-row .footerlogophone {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footer-row .footermenuphone ul {
    flex-direction: column;
    margin-top: 10px;
  }

  .footer {
    padding: 43px 42px 34px 46px;
  }

  .footericonphone {
    width: 100%;
    margin-top: 20px;
  }

  .footermenu ul li button {

    font-size: 21px;
  }

  .footericonphone ul {
    justify-content: center;

  }

  .footericons ul {
    gap: 47px;
  }

  .sticky-box-bottom-content {
    flex-direction: column;
  }

  .footermenuphone {
    margin-top: 42px;
  }

  .sticky-box-bottom-content {
    gap: 0;
  }

  .sticky-box-bottom-content h3 {
    font-size: 38px;
    margin-bottom: 28px;
    max-width: 220px;
  }

  .sticky-box {
    padding: 30px 24px 40px 30px;
  }

  .sticky-box-top-content img {
    width: 10px;
    height: 10px;
  }

  .get-in-touch-section p {
    max-width: 270px;
    margin: 25px auto;
  }

  .get-in-touch-section form .form-col-2 {
    flex-direction: column;
  }

  .get-in-touch-section form .form-col-2 input {
    width: 100%;
  }

  .form-col-between {
    flex-direction: column;
    gap: 25px;
  }

  .form-col-between .solid-btn {
    padding: 18px 90px;
    font-size: 16px;
    color: #000;
    margin-top: 25px;
  }

  .sticky-box-bottom-content p {
    margin: 28px auto 0 auto;
    font-size: 18px;
    max-width: 230px;
    text-align: center;

  }

  .sticky-boxes-section {
    margin-top: 20px;
  }





  .row {
    gap: 30px;
  }

  .two-col-sec,
  .hero-sec {
    overflow: hidden;
  }

  .box-hidden-content {
    padding: 30px 20px 65px 20px;
  }

  .box-hidden-content h4 {
    font-size: 33px;
    max-width: 224px;
    line-height: 40px;
  }

  .marquee {

    gap: 50px;

  }

  .phonelearn {
    margin-top: 15px;
  }

  .stickynav {
    background-color: #1B191D;
    z-index: 434343;
  }

  .navbar {
    max-width: 313px;
    padding-left: 0;
    padding-right: 0;
  }

  .navbtns .outline-btn {
    padding-right: 0;
  }

  .values-section .swiper-slide-active {
    transform: scale(1);
    opacity: 100%;
  }

  .values-section .swiper {
    padding: 30px 0;
    margin-top: 30px;
  }
}